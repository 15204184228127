import React, { useState } from "react";
import AddFileTemplate from "./AddFileTemplate";
import { getLangDescription } from "../../Shared/Network";
import './Templates.scss';
import { Button, Chip, createStyles, FormControl, MenuItem, Select, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useRef } from "react";
import { useUserContext } from "../../Providers/UserContext";
import { levels } from "../../Shared/Utils";

const useStyles = makeStyles((theme) =>
  createStyles({
    textInput: {
      marginBottom: 20,
    },

    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  })
);

const TemplateEdit = ({ template, sendSaveTemplate, sendDeleteTemplate, setTemplatesData, setTemplate, templatesData }) => {
  const [project, setProject] = useState(
    template && template.project ? template.project : ""
  );

  const [discipline, setDiscipline] = useState(
    template && template.discipline ? template.discipline : ""
  );

  // const [description, setDescription] = useState("");
  // const [title, setTitle] = useState("");

  const [isEditFile, setIsEditFile] = useState(false);

  const [materialData, setMaterialData] = useState(
    template && template.filesList ? template.filesList : []
  );

  // const [loadingSave, setLoadingSave] = useState(false);
  // const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [saveStatus, setSaveStatus] = useState(false);

  const [deletedFiles, setDeletedFiles] = useState([]);
  // const [mondlyTopic, setMondlyTopic] = useState(template ? template.mondlyTopic : null);
  const { userToken } = useUserContext();
  
  const handleDelete = (file) => {
    if (file instanceof File) {
      const index = materialData.indexOf(file);
      materialData.splice(index, 1);
      setMaterialData([...materialData]);
    } else {
      deletedFiles.push(file)
      setDeletedFiles([...deletedFiles]);
    }
  };
  
  const languages = [
    { lang: 'en_us', title: 'English' },
    { lang: 'es', title: 'Spanish' },
    { lang: 'br', title: 'Portuguese' },
    { lang: 'ar', title: 'Arabic' },
    { lang: 'zh', title: 'Chinese' },
    { lang: 'fr', title: 'French' },
    { lang: 'de', title: 'German' },
    { lang: 'it', title: 'Italian' },
    { lang: 'pl', title: 'Polish' },
    { lang: 'ro', title: 'Romanian' },
    { lang: 'ru', title: 'Russian' },
    { lang: 'vi', title: 'Vietnamese' },
    { lang: 'ja', title: 'Japanese' },
    { lang: 'ko', title: 'Korean' },
    { lang: 'tr', title: 'Turkish' },
    { lang: 'af', title: 'Afrikaans' },
    { lang: 'bg', title: 'Bulgarian' },
    { lang: 'cz', title: 'Czech' },
    { lang: 'da', title: 'Danish' },
    { lang: 'el', title: 'Greek' },
    { lang: 'fa', title: 'Persian' },
    { lang: 'fi', title: 'Finnish' },
    { lang: 'he', title: 'Hebrew' },
    { lang: 'hi', title: 'Hindi' },
    { lang: 'hr', title: 'Croatian' },
    { lang: 'hu', title: 'Hungarian' },
    { lang: 'id', title: 'Indonesian' },
    { lang: 'nb', title: 'Norwegian' },
    { lang: 'nl', title: 'Dutch' },
    { lang: 'sv', title: 'Swedish' },
    { lang: 'th', title: 'Thai' },
    { lang: 'ua', title: 'Ukrainian' },
    { lang: 'bn', title: 'Bengali' },
  ];

  // const [selectedLang, setSelectedLang] = useState('en_us');
  const selectedLang = useRef('en_us');

  const [langData, setLangData] = useState(template && 
    {[selectedLang.current]: 
      {
        title: template.title,
        description: template.description,
        mondlyTopic: template.mondlyTopic
      }
    });

  const [level, setLevel] = useState(template.level || 'Beginner');

  const setNewTemplateLang = (templateData) => {
    const newData = {
      ...langData,
      [selectedLang.current]: {
        title: templateData?.title || '',
        description: templateData?.description || '',
        mondlyTopic: templateData?.mondlyTopic || ''
      }
    }
    setLangData(newData)    
  };


  const changeLangDescription = async (lang) => {

    selectedLang.current = lang;
    if (!langData[lang]) {
      await getLangDescription(template.id, lang, setNewTemplateLang, userToken);
    } else {
      setLangData({...langData})
    }
  }

  const classes = useStyles();

  return (
    <div
      style={{
        padding: 20,
        border: "1px solid #ccc",
        marginTop: 20,
        borderRadius: 12,
      }}
    >
      <div className="language-select-wrap">
            <FormControl style={{width: "150px", marginBottom: "20px"}}>
            <Select
              value={selectedLang.current}
              onChange={(e) => {
                changeLangDescription(e.target.value)
              }
            }
            >
              {languages.map((language) =>  {
                return <MenuItem value={language.lang}>{language.title}</MenuItem>
              })}              
            </Select>
          </FormControl>
          </div>
      <div>
        <TextField
          id="Discipline"
          label="Discipline"
          value={discipline}
          onChange={(e) => {
            setDiscipline(e.target.value);
          }}
          variant="outlined"
          fullWidth
          className={classes.textInput}
        />
        <FormControl style={{width: "100%", marginBottom: "20px"}}>
            <Select
              value={level}
              onChange={(e) => setLevel(e.target.value)}
            >
              {levels.map((level) =>  {
                return <MenuItem value={level}>{level}</MenuItem>
              })}              
            </Select>
          </FormControl>

        {/* <TextField
          id="Level"
          label="Level"
          value={level}
          onChange={(e) => {
            setLevel(e.target.value);
          }}
          variant="outlined"
          fullWidth
          className={classes.textInput}
        /> */}

        <TextField
          id="title"
          label="Title"
          value={langData?.[selectedLang.current]?.title || ""}
          onChange={(e) => {
            if (selectedLang.current) {
              const newData = {
                ...langData,
                [selectedLang.current]: {
                  title: e.target.value,
                  description: langData?.[selectedLang.current]?.description || "",
                  mondlyTopic: langData?.[selectedLang.current]?.mondlyTopic || ''
                }
              }
              setLangData(newData)
            }
            // selectedLang && setLangData({...langData, [selectedLang]: {title: e.target.value}} );
            // setSaveStatus(true);
          }}
          variant="outlined"
          fullWidth
          className={classes.textInput}
        />
        <TextField
          id="MondlyTopic"
          label="Mondly Topic"
          className={classes.textInput}
          value={langData?.[selectedLang.current]?.mondlyTopic || ""}
          onChange={(e) => {
            if (selectedLang.current) {
              const newData = {
                ...langData,
                [selectedLang.current]: {
                  mondlyTopic: e.target.value,
                  description: langData?.[selectedLang.current]?.description || "",
                  title: langData?.[selectedLang.current]?.title || ''
                }
              }
              setLangData(newData)
            }
            // selectedLang && setLangData({...langData, [selectedLang]: {title: e.target.value}} );
            // setSaveStatus(true);
          }}
        />
        <TextField
        rows={5}
          id="Description"
          label="Description"
          value={langData?.[selectedLang.current]?.description || ""}
          onChange={(e) => {
            if (selectedLang.current) {
              const newData = {
                ...langData,
                [selectedLang.current]: {
                  description: e.target.value,
                  title: langData?.[selectedLang.current]?.title || "",
                  mondlyTopic: langData?.[selectedLang.current]?.mondlyTopic || ''
                }
              }
              setLangData(newData)
            }
            // selectedLang && setLangData({...langData, [selectedLang]: {description: e.target.value }} );
            // setSaveStatus(true);
          }}
          variant="outlined"
          fullWidth
          multiline
          className={classes.textInput}
        />
        {/* <div style={{ textAlign: "right" }}>
          <Button
          onClick={(e) => {
            saveTemplateDescription()
          }}
          // loading={loadingUpdate}
          // loadingPosition="start"
          color="secondary"
          variant="contained"
        >
          Update
        </Button>
        </div> */}


        {isEditFile ?
          <div>
           <AddFileTemplate 
            setTemplatesData={setTemplatesData}
            setTemplate={setTemplate}
            templatesData={templatesData}
            setMaterialData={setMaterialData}
            materialData={materialData}
            template={template}
            setIsEditFile={setIsEditFile}
           />
          </div>
          :
          <>
          <div style={{
            display: 'flex'
          }}>
          {materialData && 
            Object.values(materialData).map((file) => {
              return (
                <Chip
                  label={file.name || file.split('/').reverse()[0]}
                  onDelete={() => handleDelete(file.name)}
                  color={!deletedFiles.includes(file.name) ? 'primary' : 'secondary'}
                  // onClick={() => {
                  //   handleDelete(file.name)
                  // }}
                />
              )
          })}
          </div>
          
          <div
              style={{
                width: 60,
                height: 30,
                backgroundColor: "#aaa",
                borderRadius: 6,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: 5,
                fontSize: 13,
                cursor: "pointer",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={(e) => {
                setIsEditFile(true);
              }}
            >
              + Add
            </div>
            </>
          }

        <div style={{ textAlign: "center" }}>
          <Button
          onClick={(e) => {
            // setLoadingSave(true)
            sendSaveTemplate({
              project,
              discipline,
              level,
              langData,
              // mondlyTopic,
              // title: title,
              deletedFiles: deletedFiles,
              // description: description,
            });
          }}
          // loading={loadingSave}
          // loadingPosition="start"
          // startIcon={<SaveIcon />}
          variant="contained"
          disabled={saveStatus}
        >
          Save
        </Button>
        </div>

        <div style={{ textAlign: "center", marginTop: 30 }}>
          <Button
            onClick={(e) => {
              sendDeleteTemplate(template.id, userToken);
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TemplateEdit;
