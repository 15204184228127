import React, { useEffect, useState } from "react";
import SessionEdit from "./SessionEdit";
import SessionCard from "./SessionCard";
import { deleteSession, saveSession } from "../../../components/Shared/Network";
import { useUserContext } from  '../../Providers/UserContext';
import { ArrowBackIos } from "@mui/icons-material";
import { TextField } from "@mui/material";

const Sessions = ({ sessions, templates, tutors }) => {
  const [sessionsData, setSessionsData] = useState(sessions);
  const [editSession, setEditSession] = useState(null);

  const [startDate, setStartDate] = useState(new Date());
  const [numOfDays, setNumOfDays] = useState(30);
  const [daysArr, setDaysArr] = useState([]);
  const [displayStatus, setDisplayStatus] = useState(false);

  const { hasRole, userToken } = useUserContext();

  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const matchDate = (date, day) => {
    let plan = new Date(date);
    // console.log(plan, day);
    if (
      plan.getDate() === day.getDate() &&
      plan.getMonth() === day.getMonth() &&
      plan.getFullYear() === day.getFullYear() 
    ) {
      return true;
    }
    return false;
  };

  const getDaySessions = (day) => {
    let res = [];
    for (let i = 0; i < sessionsData.length; i++) {
      if (matchDate(sessionsData[i].date, day)) {
        res.push(sessionsData[i]);
      }
    }
    return res;
  };

  useEffect(() => {
    let cDaysArr = [];
    let now = new Date(startDate.toISOString());
    let end = new Date().setDate(now.getDate() + numOfDays);
    for (var d = now; d <= end; d.setDate(d.getDate() + 1)) {
      cDaysArr.push(new Date(d));
    }
    setDaysArr(cDaysArr);
  }, [startDate, numOfDays]);

  // useEffect(() => {
  //   const url = new URL(window.location.href);
  //   if(!url.searchParams.get("videoType")){
  //     setVideoType("elos");
  //   }
  //   else {
  //     setVideoType(url.searchParams.get("videoType"));
  //   }
  // }, []);

  const saveSessionRes = (res) => {
    console.log(res);
    let index = sessionsData.findIndex((e) => e.id === res.session.id);
    let a = [...sessionsData];

    if (index >= 0) {
      a[index] = res.session;
    } else {
      a.push(res.session);
    }

    setSessionsData(a);
    setEditSession(null);
  };

  const sendSaveSession = (data) => {
    console.log(data);
    saveSession(editSession.id, data, userToken, saveSessionRes);
  };

  const deleteSessionRes = (res) => {
    if (!res.sessionDelete) {
      if(!window.alert(res.message))
                  {
                      return;
                  }
    } else {
      let index = sessionsData.findIndex((e) => e.id === res.sessionDelete.id);
      sessionsData.splice(index, 1);
  
      setSessionsData([...sessionsData]);
      setEditSession(null);
    }
    
  };

  const sendDeleteSession = (sessionId, token) => {
    deleteSession(sessionId, token, deleteSessionRes);
  };

  return (
    <>
    <div style={{ justifyContent: "center", display: displayStatus ? "none" : "flex" }}>
        { editSession && !editSession.id ? (
          <div style={{ width: 1024 }}>
            <div style={{ textAlign: "left", marginBottom: 20 }}>
              <div 
                style = {{cursor: 'pointer'}}
                onClick={(e) => {
                  setEditSession(null);
                }}
              >
                {/* <div style={{ display: "flex", alignItems: "center", cursor: 'pointer' }}>
                  <ArrowBackIos fontSize="small" />
                  Back
                </div> */}
              </div>
              <SessionEdit
                session={editSession}
                setEditSession={setEditSession}
                templates={templates}
                tutors={tutors}
                daySessions={getDaySessions(new Date(editSession.date))}
                sendSaveSession={sendSaveSession}
              />
            </div>
          </div>
        ) :
        (

        <div style={{ width: 1024 }}>
          <div style={{ textAlign: "right", marginBottom: 20, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <TextField
              id="date"
              label="Start Date"
              type="date"
              defaultValue={startDate && startDate.toISOString().split("T")[0]}
              onChange={(e) => {

                setStartDate(new Date(e.target.value));
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div>
            {daysArr.map((day) => (
              
              <div
                key={day.toISOString()}
                style={{
                  minHeight: 180,
                  display: "flex",
                  gap: 20,
                  marginBottom: 20,
                }}
              >
                <div
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 12,
                    padding: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexFlow: "wrap",
                    backgroundColor: "#333",
                  }}
                >
                  <div
                    style={{ fontSize: 26, color: "white", fontWeight: 600, width:90, textAlign: 'center' }}
                  >
                    {month[day.getMonth()]} {day.getDate().toString().padStart(2, '0')}
                  </div>
                  <div style={{ color: "#ccc" }}> {weekday[day.getDay()]} </div>
                </div>

                <div
                  style={{
                    border: "1px solid #aaa",
                    flexGrow: 4,
                    flexBasis: "100%",
                    padding: 20,
                    borderRadius: 6,
                  }}
                >            
                {hasRole('Admin') && (
                  <div
                    style={{
                      width: 60,
                      height: 30,
                      backgroundColor: "#aaa",
                      borderRadius: 6,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      padding: 5,
                      fontSize: 13,
                      cursor: "pointer",
                      color: "white",
                      fontWeight: "bold",
                    }}
                    onClick={(e) => {
                      setEditSession({
                        date: day

                      });
                    }}
                  >
                    + Add
                  </div>
                )}
                  {sessionsData
                    .filter(
                      (e) =>
                        matchDate(e.date, day)
                    )
                    .map((csession) => (
                      <div key={csession.id}>
                        {editSession && editSession.id === csession.id ? (
                          <SessionEdit
                            session={editSession}
                            templates={templates}
                            tutors={tutors}
                            daySessions={getDaySessions(new Date(editSession.date))}
                            inline
                            setEditSession={setEditSession}
                            sendSaveSession={sendSaveSession}
                            sendDeleteSession={sendDeleteSession}
                          />
                        ) : (
                          <SessionCard
                            session={csession}
                            templates={templates}
                            tutors={tutors}
                            setEditSession={setEditSession}
                            setDisplayStatus={setDisplayStatus}
                          />
                        )}
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
          <div
            style={{ textAlign: "center", marginTop: 10, paddingBottom: 20 }}
          >
            <div
              onClick={(e) => {
                setNumOfDays(numOfDays + 30);
              }}
            >
              - More -
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default Sessions;
