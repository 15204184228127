import { TextField } from "@mui/material";
import { useUserContext } from "../Providers/UserContext";
import {
  apiGetUserList,
  getPollsData,
  getUserFeedbacksData,
} from "../Shared/Network";
import { downloadTextAsFile } from "../Shared/Utils";
import { useState } from "react";

const API = () => {
  const [startDate, setStartDate] = useState(new Date());

  const { userToken } = useUserContext();

  const getUserList = async (fromDate) => {
    const from = fromDate.toISOString().split("T")[0];
    const userList = await apiGetUserList(from, userToken);
    downloadTextAsFile(userList, `${from}.csv`);
  };

  const getUserPolls = async (fromDate) => {
    const from = fromDate.toISOString().split("T")[0];
    const vipUpcoming = await getPollsData(from, userToken);
    downloadTextAsFile(vipUpcoming, `${from}.csv`);
  };

  const getUserFeedbacks = async (fromDate) => {
    const from = fromDate.toISOString().split("T")[0];
    const userFeedbacks = await getUserFeedbacksData(from, userToken);
    downloadTextAsFile(userFeedbacks, `feedbacks-${from}.csv`);
  };

  return (
    <>
      <div style={{ justifyContent: "center", margin: "0 150px" }}>
        <div style={{ marginBottom: "30px" }}>
          <TextField
            id="date"
            label="Start Date"
            type="date"
            defaultValue={startDate && startDate.toISOString().split("T")[0]}
            onChange={(e) => {
              setStartDate(new Date(e.target.value));
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div style={{ display: "flex", gap: "40px", flexDirection: "column" }}>
          <div
            onClick={() => getUserList(startDate)}
            style={{
              width: 150,
              height: 30,
              borderRadius: 12,
              padding: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "wrap",
              backgroundColor: "#a9dbd2",
              cursor: "pointer",
            }}
          >
            Get User List
          </div>
          <div
            onClick={() => getUserPolls(startDate)}
            style={{
              width: 150,
              height: 30,
              borderRadius: 12,
              padding: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "wrap",
              backgroundColor: "#F44336",
              cursor: "pointer",
            }}
          >
            User Polls
          </div>
          <div
            onClick={() => getUserFeedbacks(startDate)}
            style={{
              width: 150,
              height: 30,
              borderRadius: 12,
              padding: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "wrap",
              backgroundColor: "#d7b98f",
              cursor: "pointer",
            }}
          >
            User Feedbacks
          </div>
        </div>
      </div>
    </>
  );
};
export default API;
