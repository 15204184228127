import React, { useEffect, useState } from "react";
import TemplateEdit from "./TemplateEdit";
import {
  deleteTemplate,
  saveTemplate,
  getContentEnv
} from "../../../components/Shared/Network";
import Avatar from "../Tutors/Avatar";
import './Templates.scss';
import { useUserContext } from  '../../Providers/UserContext';
import { Button } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";

const Templates = ({ templatesData, tutors, setTemplatesData }) => {
  // const [templatesData, setTemplatesData] = useState(templates);
  const [template, setTemplate] = useState(null);
  const { hasRole, userToken } = useUserContext();

  const cap = (string) => {
    return string && string.charAt(0).toUpperCase() + string.slice(1);
  };

  const templateColor = (level) => {
    switch (level) {
      case "A1":
        return "rgb(220,236,255)";
      case "A2":
        return "rgb(255,221,201)";
      default:
        return "rgb(220,236,255)";
    }
  };

  const deteleTemplateRes = (res) => {
    if (!res.templateDelete) {
      if(!window.alert
        (`Cannot delete template.\nThe following attached sessions has enrolled users:\n${res.map((session) => {return session.id + ', '})}`))
                  {
                      return;
                  }
    }

    let index = templatesData.findIndex((e) => e.id === res.templateDelete.id);
    templatesData.splice(index, 1);

    setTemplatesData([...templatesData]);
    setTemplate(null);
  };

  const sendDeleteTemplate = (templateId, token) => {
    deleteTemplate(templateId, token, deteleTemplateRes);
  };

  const saveTemplateRes = (res) => {
    console.log(res);
    let index = templatesData.findIndex((e) => e.id === res.template.id);
    let a = [...templatesData];
    if (index >= 0) {
      a[index] = res.template;
    } else {
      a.push(res.template);
    }
    setTemplatesData(a);
    setTemplate(null);
  };

  const sendSaveTemplate = (data) => {
    // console.log(data);
    saveTemplate(template.id, data, userToken, saveTemplateRes);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {!template ? (
        <div style={{ width: 1024 }}>
          {hasRole('Admin') && (
            <div style={{ textAlign: "right", marginBottom: 20 }}>
              <Button
                variant="contained"
                onClick={(e) => {
                  setTemplate({});
                }}
              >
                New Template
              </Button>
            </div>
          )}
          {templatesData.map((template) => (
            <div
              style={{
                border: "1px solid #ccc",
                borderRadius: 12,
                cursor: "pointer",
                marginBottom: 10,
                backgroundColor: templateColor(template.level),
              }}
              onClick={(e) => {
                if(hasRole('Admin')){
                  setTemplate(template);
                }
              }}
            >
              <div style={{ padding: 20 }}>
                <div
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    marginBottom: 10,
                  }}
                >
                  {cap(template.title)}
                </div>
                <div style={{ display: "flex", gap: 30 }}>
                  <div style={{ width: 260, flexShrink: 0 }}>
                    <div style={{ marginBottom: 10, fontWeight: 500 }}>
                      {cap(template.discipline)}: {cap(template.level)}
                    </div>

                    <div style={{ display: "flex", flexWrap: 'wrap', gap:3 }}>
                      {tutors.map((tutor) => (
                        <>
                          {tutor.templates &&
                            tutor.templates.findIndex(
                              (obj) => obj.id === template.id
                            ) >= 0 && (
                              <div>
                                <Avatar width={32} thumb={tutor.thumb} />{" "}
                              </div>
                            )}
                        </>
                      ))}
                    </div>
                  </div>

                  <div style={{whiteSpace: 'pre-wrap'}}>{cap(template.description)}</div>
                </div>
                <div className="session-material">
                    <div style={{ width: 260, flexShrink: 0 }}></div>
                    <div style={{ width: '100%', display: 'flex', marginLeft: 30 }}>
                    {template && template.filesList && template.filesList.map((file) => {
                      
                      return (
                        <div
                        key={file.name}
                        className='material-file'
                        title={file.name}
                        onClick={(e)=>{ e.stopPropagation(); window.open( (getContentEnv() === 'prod' ? 'https://d11s3x8mtmgzr2.cloudfront.net' : 'https://d24mswg82xnqvq.cloudfront.net' ) + `/assets/${template.id}/${file.name}`, '_blank').focus()}}
                        >
                          {file.name}
                        </div>)
                    })}
                    </div>
                  </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div style={{ width: 1024 }}>
          <div style={{ textAlign: "left", marginBottom: 20 }}>
            <div
              onClick={(e) => {
                setTemplate(null);
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIos fontSize="small" />
                Back
              </div>
            </div>
            <TemplateEdit
              key={templatesData.id}
              template={template}
              setTemplatesData={setTemplatesData}
              setTemplate={setTemplate}
              templatesData={templatesData}
              sendDeleteTemplate={sendDeleteTemplate}
              sendSaveTemplate={sendSaveTemplate}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Templates;
