import { Button, Checkbox, FormControlLabel } from "@mui/material";
import React, { useState } from "react";
import { uploadFile } from "../../Shared/Network";
import CloseIcon from "@material-ui/icons/Close"
import { useUserContext } from "../../Providers/UserContext";


const AddFileTemplate = ({template, setIsEditFile, materialData, setMaterialData, templatesData, setTemplatesData, setTemplate}) => {

    const [checked, setChecked] = useState(true);
    const { userToken } = useUserContext();

    const saveTemplateRes = (res) => {
      console.log(res);
      let index = templatesData.findIndex((e) => e.id === res.template.id);
      let a = [...templatesData];
      if (index >= 0) {
        a[index] = res.template;
      } else {
        a.push(res.template);
      }
      setTemplatesData(a);
      setTemplate(null);
    };
    
    const [fileName, setFileName] = useState();
    const addFile = (file) => {
      const name = file.name.replace(/\.[^/.]+$/, "")
      // materialData.push(file);
      setFileName(name);
      setMaterialData(file);
    }

    const handleChange = (e) => {
      setChecked(e.target.checked);
    };

    const uploadNewFile = (data, token) => {
      console.log(data);
      uploadFile(template.id, data, token, saveTemplateRes);
    };
    
    
    return (
        <div  
        style={{
            padding: 20,
            border: "1px solid #ccc",
            marginTop: 20,
            marginBottom: 20,
            borderRadius: 12,
            position: "relative",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center"
          }}>
            <div
                style={{
                position: "absolute",
                top: 20,
                right: 20,
                color: "gray",
                cursor: "pointer",
                }}
                onClick={(e) => setIsEditFile(false)}
            >
                <CloseIcon />
            </div>

             {/* <TextField
            id="fileMaterial"
            label="Add Material Name"
            value={materialName}
            onChange={(e) => {
                setMaterialName(e.target.value);
            }}
            variant="outlined"
          /> */}
          <FormControlLabel
            checked={checked}
            onChange={handleChange}
            label="Upload to Class"
            control={<Checkbox defaultChecked/>}
         />

             <Button
            // disabled={!materialName}
            variant="contained"
            component="label"
            color={"warning"}
          > 
               Select File 
               {/* <form
                encType="multipart/form-data"
                > */}
                    <input
                    // multiple
                    onChange={(e) => {
                        addFile(e.target.files[0]);
                    }}
                        type="file"
                        hidden
                    /> 
              {/* </form> */}
           </Button>
           {fileName}
           
           <Button
           variant="contained"
           color={"success"}
          //  disabled={!fileName || !materialName}
           onClick={ (e) => {
            uploadNewFile({
              project: template.project,
              discipline: template.discipline,
              level: template.level,
              id: template.id,
              mondlyTopic: template.mondlyTopic,
              uploadToSession: checked,
              materialFile: materialData,

            }, userToken)
           }}
           >
            Add File
           </Button>
              

        </div>
    )
}

export default AddFileTemplate;