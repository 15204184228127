import { FC, createContext, useContext, useMemo, useState, memo, useCallback } from 'react';
import { LoginAPI, LoginUserContext } from '@yoda/components-core';

//type AdminRole = 'Admin' | 'Viewer' | 'Moderator';

const UserContext = createContext({
  user: null,
  azureAPI: new LoginAPI(),
  hasRole: () => false,
  hasAnyRole: () => false,
  login: () => {},
  logout: () => {},
});

export const UserProvider = memo(({ children }) => {
  const [user, setUser] = useState(null);
  const [azureAPI] = useState(() => new LoginAPI());

  const roles = useMemo(() => {
    console.log('idTokenClaims', user?.idTokenClaims);
    return user?.idTokenClaims?.roles || [];
  }, [user]);

  const userToken = useMemo(() => {
    if (user?.token) return user.token;
  }, [user?.token]);

  const hasRole = useCallback((role) => {
    // return true;
    // return role === 'Tutor'
  const queryParams = new URLSearchParams(window.location.search);
  const isTutor = queryParams.get("tutor") &&  queryParams.get("tutor") === "true";
  if (roles.includes('Admin')) {
    if (isTutor) {
      return role === 'Tutor'
    }  
  } 
  return !!roles.includes(role)
  
  }, [roles]);

  const value = useMemo(() => {
    const updatedUser = user
      ? {
        ...user,
        refreshTokenCallback: (_unusedUserArg, callback) => { // NOTE: `_unusedUserArg` has modified `refreshTokenCallback`, do not use it here
          user.refreshTokenCallback?.(user, (token) => {
            setUser({ ...user, token });
            callback(token);
          });
        },
      }
      : null;

    return {
      user: updatedUser,
      azureAPI,
      userToken,
      hasRole,
      hasAnyRole: () => !!roles.length,
      login: () => {
        azureAPI.login('loginPopup', setUser).catch(console.error);
      },
      logout: () => {
        azureAPI.logout().catch(console.error);
      },
    };
  }, [user, roles, hasRole, azureAPI, userToken]);

  return (
    <UserContext.Provider value={value}>
      <LoginUserContext.Provider value={{ user, setUser }}>
        {children}
      </LoginUserContext.Provider>
    </UserContext.Provider>
  );
});

export const useUserContext = () => useContext(UserContext);
