  import React, { useEffect, useState } from "react";
  import WeekCalendar from "react-week-calendar";
  import moment from "moment";
  import { onThumbnailUploadChange } from "../../Shared/Utils";
  import Avatar from "./Avatar";
  import { useUserContext } from  '../../Providers/UserContext';
  import "react-week-calendar/dist/style.css";
  import {Autocomplete, Box, Button, Checkbox, Collapse, createStyles, IconButton, TextField } from "@mui/material";
  import { makeStyles } from "@material-ui/core";
  import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
  import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

  const useStyles = makeStyles((theme) =>
    createStyles({
      textInput: {
        marginBottom: 20,
      },

      container: {
        display: "flex",
        flexWrap: "wrap",
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
      },
    })
  );

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const TutorEdit = ({ tutor, templates, sendDeleteTutor, sendSaveTutor }) => {

    const [value, setValue] = useState([]);
    const [checkAll, setCheckAll] = useState(tutor?.templates?.length === templates.length ? true : false);

    const { hasRole } = useUserContext();

    const [firstName, setFirstName] = useState(
      tutor && tutor.firstName ? tutor.firstName : ""
    );
    const [lastName, setLastName] = useState(
      tutor && tutor.lastName ? tutor.lastName : ""
    );
    const [email, setEmail] = useState(tutor && tutor.email ? tutor.email : "");
    const [vendorId, setVendorId] = useState(tutor?.vendorId);
    const [vendor, setVendor] = useState(tutor?.vendor);
    const [levels, setLevels] = useState([]);
    const [openB, setOpenB] = useState(false);
    const [openI, setOpenI] = useState(false);
    const [openA, setOpenA] = useState(false);

    const beginnerTemplates = templates
          .filter(item => item.level === 'Beginner')
          .sort((a, b) => {
            if(a.title > b.title) {
              return 0;
            }
            return -1;
          });
    const intermediateTemplates = templates.filter(item => item.level === 'Intermediate').sort((a, b) => {
      if(a.title > b.title) {
        return 0;
      }
      return -1;
    });
    const advancedTemplates = templates.filter(item => item.level === 'Advanced').sort((a, b) => {
      if(a.title > b.title) {
        return 0;
      }
      return -1;
    });
    const [filteredTemplates, setfilteredTemplates] = useState(
            [{id: "all", title: "All Lessons", level: "All Lessons"}].concat(beginnerTemplates
                                                                .concat(intermediateTemplates)
                                                                .concat(advancedTemplates)));


    const [thumb, setThumb] = useState(tutor && tutor.thumb ? tutor.thumb : "");
    const [uploading, setUploading] = useState(false);

    const initAvailabilityArr = (jsonSvailabilityArr) => {
      let res = [];
      for (let i = 0; i < jsonSvailabilityArr.length; i++) {
        res.push({
          start: moment(jsonSvailabilityArr[i].start),
          end: moment(jsonSvailabilityArr[i].end),
        });
      }
      return res;
    };

    const saveAvailabilityArr = (momentAvailabilityArr) => {
      let res = [];
      for (let i = 0; i < momentAvailabilityArr.length; i++) {
        res.push({
          start: momentAvailabilityArr[i].start.format(),
          end: momentAvailabilityArr[i].end.format(),
        });
      }
      return res;
    };

    const updateAvilability = tutor?.availability?.map((avail) => {

      const startDay = moment(avail.start).local().day();
      const endDay = moment(avail.end).local().day();

      const dayOfCurrentWeekStart = moment().subtract(moment().day() - startDay, 'd').set("hour", new Date(avail.start).getHours()).set("minute", new Date(avail.start).getMinutes());
      const dayOfCurrentWeekEnd = moment().subtract(moment().day() - endDay, 'd').set("hour", new Date(avail.end).getHours()).set("minute", new Date(avail.end).getMinutes());

      if (dayOfCurrentWeekStart.day() !== dayOfCurrentWeekEnd.day()) {
        
        return [
          {
            start: dayOfCurrentWeekStart.clone().local(),
            end: dayOfCurrentWeekStart.clone().set('hour', 23).set('minute', 59).local(),
          },{
            start: dayOfCurrentWeekEnd.clone().set('hour', 0).set('minute', 0).local(),
            end: dayOfCurrentWeekEnd.clone().local(),
          }
        ]
      }
      
      return {
        start: dayOfCurrentWeekStart.local(),
        end: dayOfCurrentWeekEnd.local(),
      }

    }).flat();

    const [availability, setAvailability] = useState(
      tutor && updateAvilability ? initAvailabilityArr(updateAvilability) : []
    );

    const [tutorTemplates, setTutorTemplates] = useState(
      tutor && tutor.templates ? tutor.templates : []
    );
    const [initTutorTemplates] = useState(
      tutor && tutor.templates ? tutor.templates : []
    );

    const getTutorTemplatesArr = (tutorTemplates) => {
      let res = [];

      for (let i = 0; i < templates.length; i++) {
        if (tutorTemplates?.findIndex((e) => e.id === templates[i].id) >= 0) {
          res.push(templates[i]);
        }
      }
      return res;
    };

    useEffect(() => {
        setValue(getTutorTemplatesArr(tutor?.templates))
    }, []);

    // useEffect(() => {
    //   if (value.filter((item) => item.level === 'Beginner').length 
    //         === beginnerTemplates.length && !levels.includes('Beginner')) {
    //     levels.push('Beginner');
    //   } 
    //   if (value.filter((item) => item.level === 'Intermediate').length 
    //         === intermediateTemplates.length && !levels.includes('Intermediate')) {
    //     levels.push('Intermediate');
    //   }  
    //   if (value.filter((item) => item.level === 'Advanced').length 
    //         === advancedTemplates.length && !levels.includes('Advanced')) {
    //     levels.push('Advanced');
    //   }
    //   if (value.filter((item) => item.level === 'Beginner').length === 0) {
    //     const index = levels.indexOf('Beginner');
    //     levels.splice(index, 1);
        
    //   } 
    //   if (value.filter((item) => item.level === 'Intermediate').length === 0) {
    //     const index = levels.indexOf('Intermediate');
    //     levels.splice(index, 1);  
    //   }
    //   if (value.filter((item) => item.level === 'Advanced').length === 0) {
    //     const index = levels.indexOf('Advanced');
    //     levels.splice(index, 1);
    //   }
    //   setLevels(levels);
    //   if (levels.length === 3) {
    //     setCheckAll(true);
    //   } else {
    //     setCheckAll(false);
    //   }
    //   console.log('boom trah', levels);
    // }, [value, levels])

    const checkAllChange = (event) => {
      setCheckAll(event.target.checked);
      if (event.target.checked) {
        // setValue(filteredTemplates)
        setLevels(['Advanced', 'Intermediate', 'Beginner'])
        let res = tutorTemplates;
        filteredTemplates.filter(item => item.id !== 'sort' && item.id !== 'all').forEach((val) => {
          res.push({ id: val.id });
      });
        setValue(getTutorTemplatesArr(res));
        setTutorTemplates(res);
      } else {
        setValue([]);
        setLevels([]);
        setTutorTemplates([])
      }
    }

    const classes = useStyles();

    const checkLevel = (event) => {
      if (event.target.checked) {
        levels.push(event.target.value)
        setLevels(levels);
        let res = tutorTemplates;
        filteredTemplates.forEach((val) => {
          if (val.level === event.target.value) {
            res.push({ id: val.id });
          }
        });
        setValue(getTutorTemplatesArr(res));
        setTutorTemplates(res);
      } else {
        const index = levels.indexOf(event.target.value);
        if (index > -1) {
          levels.splice(index, 1);
          setLevels(levels);
        }
        if (value.filter((item) => item.level === event.target.value).length > 0) {
          let res = tutorTemplates;
          for (let index = res.length - 1; index >= 0; index--) {
            // console.log(val, filteredTemplates);
            const resLevel = filteredTemplates.find((fTemplate) => fTemplate.id === res[index].id).level;
            if (resLevel === event.target.value) {
              res.splice(index, 1);
              
            }
          };
          setValue(getTutorTemplatesArr(res));
          setTutorTemplates(res);
        } else {
          setLevels([]);
          setValue([]);
          setTutorTemplates([]);
        }
      }
      console.log('boom', levels)
    }

    const imageUploaded = (url) => {
      setThumb(url);
      setUploading(false);
    };

    const CollapseGroup = ({params}) => {
      if (params.group === 'All Lessons') {
        return (
          <Box>
            <Checkbox 
              value='all'
              sx={{
                '&.Mui-checked': {
                color: "rgba(158, 0, 126, 1)",
                  },
                  '&.MuiCheckbox-indeterminate': {
                  color: "rgba(158, 0, 126, 1)",
                    }
                }}
              checked={value.length === templates.length || levels.length === 3 || checkAll }
              indeterminate={(value.length > 0 && value.length < templates.length)}
              onChange={(event) => {
                checkAllChange(event)
              }
            } 
              onMouseDown={(e) => e.preventDefault()}
            />
            <b>Select All</b>
            </Box>
          )
      }
      return (
            <Box>
                    {(params.group === 'Beginner')
                    &&
                    <><Checkbox 
                      id={params.group}
                      value={params.group}
                      sx={{
                        '&.Mui-checked': {
                        color: "rgba(158, 0, 126, 1)",
                        },
                        '&.MuiCheckbox-indeterminate': {
                          color: "rgba(158, 0, 126, 1)",
                          }
                      }}
                      checked={checkAll
                        || value.filter((item) => item.level === params.group).length === beginnerTemplates.length}
                      indeterminate={value.filter((item) => item.level === params.group).length > 0 
                        && value.filter((item) => item.level === params.group).length <  beginnerTemplates.length}
                      onChange={(event) => {
                        checkLevel(event)
                      }
                    } 
                      onMouseDown={(e) => e.preventDefault()}
                    />
                    <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpenB(!openB)}
                  >
                    {openB ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>{" "}
                  <b style={{ cursor: "pointer" }} onClick={() => setOpenB(!openB)}>
                    {params.group}
                  </b>
                  <Collapse in={openB} timeout="auto">
                      {params.children}
                  </Collapse>
                    </>}
                    {(params.group === 'Advanced')
                    &&
                    <><Checkbox 
                      id={params.group}
                      value={params.group}
                      sx={{
                        '&.Mui-checked': {
                        color: "rgba(158, 0, 126, 1)",
                        },
                        '&.MuiCheckbox-indeterminate': {
                          color: "rgba(158, 0, 126, 1)",
                          }
                      }}
                      checked={checkAll 
                        || value.filter((item) => item.level === params.group).length === advancedTemplates.length}
                      indeterminate={value.filter((item) => item.level === params.group).length > 0 
                          && value.filter((item) => item.level === params.group).length <  advancedTemplates.length}
                      onChange={(event) => {
                        checkLevel(event);
                      }
                    } 
                      onMouseDown={(e) => e.preventDefault()}
                    />
                    <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpenA(!openA)}
                  >
                    {openA ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>{" "}
                  <b style={{ cursor: "pointer" }} onClick={() => setOpenA(!openA)}>
                    {params.group}
                  </b>
                  <Collapse in={openA} timeout="auto">
                      {params.children}
                  </Collapse>
                  </>}
                    {(params.group === 'Intermediate')
                    &&
                    <><Checkbox 
                      id={params.group}
                      value={params.group}
                      sx={{
                        '&.Mui-checked': {
                        color: "rgba(158, 0, 126, 1)",
                        },
                        '&.MuiCheckbox-indeterminate': {
                          color: "rgba(158, 0, 126, 1)",
                          }
                      }}
                      checked={checkAll 
                        || value.filter((item) => item.level === params.group).length === intermediateTemplates.length}
                      indeterminate={value.filter((item) => item.level === params.group).length > 0 
                        && value.filter((item) => item.level === params.group).length <  intermediateTemplates.length}
                      onChange={(event) => {
                        checkLevel(event)
                      }
                    } 
                      onMouseDown={(e) => e.preventDefault()}
                    />
                    <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpenI(!openI)}
                  >
                    {openI ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>{" "}
                  <b style={{ cursor: "pointer" }} onClick={() => setOpenI(!openI)}>
                    {params.group}
                  </b>
                  <Collapse in={openI} timeout="auto">
                      {params.children}
                  </Collapse>
                  </>}
              </Box>
      );
    };

    return (
      <div
        style={{
          padding: 20,
          border: "1px solid #ccc",
          marginTop: 20,
          borderRadius: 12,
        }}
      >
        <div style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            disabled={firstName && lastName && validateEmail(email) ? false : true}
            onClick={(e) => {
              sendSaveTutor({
                firstName: firstName,
                lastName: lastName,
                email: email,
                vendorId: parseInt(vendorId) || undefined,
                vendor,
                availability: saveAvailabilityArr(availability),
                thumb: thumb,
                templates: tutorTemplates.map((tutor) => {
                  if (tutor.id !== 'all') {
                  // needed to fix Yohann bug where full template was returned
                  return { id: tutor.id }; 
                  }
                }),
              });
            }}
          >
            Save
          </Button>
        </div>

        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 10,
              marginBottom: 30,
            }}
          >
            <Avatar width={160} thumb={thumb} />
            <Button
              variant="outlined"
              disabled={uploading}
              component="label"
              size="small"
              style={{
                textAlign: "center",
              }}
            >
              Select
              <input
                type="file"
                onChange={(e) => {
                  setUploading(true);
                  onThumbnailUploadChange(e, imageUploaded, "tutoring", 200);
                }}
                hidden
              />
            </Button>
          </div>
          <div>
            <div style={{ display: "flex", gap: 10 }}>
              <TextField
                id="First Name"
                label="First Name*"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                variant="outlined"
                fullWidth
                // style={{ width: "45%" }}
                className={classes.textInput}
                autoComplete="off"
              />
              <TextField
                id="Last Name"
                label="Last Name*"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                fullWidth
                variant="outlined"
                // style={{ width: "45%" }}
                className={classes.textInput}
                autoComplete="off"
              />
            </div>
            <div style={{ display: "flex", gap: 10 }}>
              <TextField
                id="Email"
                label="Email*"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                fullWidth
                variant="outlined"
                className={classes.textInput}
                autoComplete="off"
                disabled = { hasRole('Admin') ? false : true}
              />
                <TextField
                id="vendor"
                label="vendor"
                value={vendor}
                onChange={(e) => {
                  setVendor(e.target.value);
                }}
                fullWidth
                variant="outlined"
                className={classes.textInput}
                autoComplete="off"
              />
              <TextField
                id="vendorId"
                label="vendorId"
                value={vendorId}
                onChange={(e) => {
                  setVendorId(e.target.value);
                }}
                fullWidth
                variant="outlined"
                className={classes.textInput}
                autoComplete="off"
              />
            </div>
          </div>

          <div style={{ marginBottom: 20 }}>
            <div style={{ marginBottom: 5 }}>
              <div style={{ marginBottom: 20 }}>Can teach the following courses:</div>
            </div>
            <Autocomplete
              multiple
              id="tags-outlined"
              ListboxProps={{ style: { maxHeight: 300 } }}
              ChipProps={{style: {fontSize: "12px", height: "30px", letterSpacing: "normal" } }}
              options={filteredTemplates}
              disableCloseOnSelect
              getOptionLabel={(option) => `${option.title}`}
              defaultValue={getTutorTemplatesArr(initTutorTemplates)}
              groupBy={(option) => option.level}
              disabled = { hasRole('Admin') ? false : true}
              value={value}
              onChange={(event, newValue, reason) => {
                if (reason === "selectOption") {
                  setValue(newValue);
                  let res = tutorTemplates;
                  const indx = res.findIndex(item => item.id === newValue.id);
                  if (indx === -1) {
                    newValue.forEach((val) => {
                      res.push({ id: val.id });
                    });
                  setTutorTemplates(res);
                  }
                } else if (reason === "removeOption") {
                  console.log('remove', newValue)
                  setCheckAll(false);
                  setValue(newValue);
                  let res = tutorTemplates;
                  newValue.forEach((val) => {
                    res.push({ id: val.id });
                });
                setTutorTemplates(res);
                } else if (reason === "clear") {
                  setLevels([]);
                  setValue([]);
                  setCheckAll(false);
                  setTutorTemplates([])
                }
              }}
              componentsProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'flip',
                      enabled: false
                    },
                    {
                      name: 'preventOverflow',
                      enabled: false
                    }
                  ]
                }
              }}
              renderGroup={(paramsGroup) => {
                return <CollapseGroup key={paramsGroup.group} params={paramsGroup} />;
              }}
              renderOption={(props, option, { selected }) => (
                // option?.id === 'all' ? (
                // <li>
                //   <Checkbox 
                //     value='all'
                //     sx={{
                //       '&.Mui-checked': {
                //       color: "rgba(158, 0, 126, 1)",
                //        },
                //        '&.MuiCheckbox-indeterminate': {
                //         color: "rgba(158, 0, 126, 1)",
                //          },
                //      }}
                //     key={option.id}
                //     checked={levels.length === 3 || checkAll}
                //     indeterminate={levels.length > 0 && levels.length < 3}
                //     onChange={(event) => {
                //       checkAllChange(event)
                //     }
                //   } 
                //     onMouseDown={(e) => e.preventDefault()}
                //   />
                //   {option.title}
                // </li>
                // ) :
                // : option?.id === 'sort' ?
                // <li>
                //   <Checkbox 
                //     value={option.title}
                //     sx={{
                //       '&.Mui-checked': {
                //       color: "rgba(158, 0, 126, 1)",
                //        },
                //      }}
                //     key={option.id}
                //     checked={levels.includes(option.level) || checkAll}
                //     // icon={iconMedium}
                //     // checkedIcon={checkedIconMedium}
                //     style={{ marginLeft: 20}}
                //     onChange={(event) => {
                //       checkLevel(event)
                //     }
                //   } 
                //     onMouseDown={(e) => e.preventDefault()}
                //   />
                //   {option.title}
                // </li> 
                // :
              <div {...props}>                
                  <Checkbox
                  key={option.id}
                  sx={{
                    '&.Mui-checked': {
                    color: "rgba(158, 0, 126, 1)",
                    },
                  }}
                    // icon={iconSmall}
                    // checkedIcon={checkedIconSmall}
                    style={{ marginLeft: 40}}
                    checked={selected || checkAll}
                    onMouseDown={(e) => e.preventDefault()}
                    // onChange={() => updateCourse()}
                  />
                  {option?.title}
                </div>
                
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select lessons"
                />
              )}
            /> 
          </div>

          <div style={{ marginBottom: 20 }}>
            <div style={{ marginBottom: 10, color: "#666" }}>
              Weekly Availability
            </div>
            {!hasRole("Admin") && (
            <div style={{
                height: 630, 
                width: '100%',
                background: 'white', 
                position: 'absolute',
                left: 0,
                zIndex: 20,
                opacity: 0.5
              }}
            />)}
            <WeekCalendar
              numberOfDays={7}
              firstDay={moment().day(0)}
              scaleUnit={60}
              startTime={moment({ h: 0, m: 0 })}
              endTime={moment({ h: 23, m: 59 })}
              selectedIntervals={availability}
              dayFormat={"dd"}
              useModal={false}
              showModalCase={["create"]}
              onIntervalSelect={(e) => {
                setAvailability([...availability, ...e]);
              }}
              onIntervalUpdate={(e) => {
                console.log(e);
              }}
              onEventClick={(e) => {

                let index = availability.findIndex(
                  (obj) => obj.start.format() === e.start.format()
                );
                let newAvailability = [...availability];
                newAvailability.splice(index, 1);
                setAvailability(newAvailability);
              }}
            />
          </div>

          {tutor && <div style={{ textAlign: "center", marginTop: 30 }}>
            <Button onClick={(e) => sendDeleteTutor(tutor.id)}>
              Delete Tutor
            </Button>
          </div>}
        </div>
      </div>
    );
  };

  export default TutorEdit;
