import Admin from "./components/Admin";
import { UserProvider, useUserContext } from './components/Providers/UserContext';
import { useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { LoginScreen } from "@yoda/components-core";;

function AppBody() {
  const { pathname } = useLocation();
  const { user, hasRole, azureAPI } = useUserContext();
  const [ fullPath ] = useState(window.location.href.replace(window.location.origin, ''));
  
  const queryParams = new URLSearchParams(window.location.search);
  const noAzureLogin = queryParams.get("noAzure") &&  queryParams.get("noAzure") === "1";
 
  if(!noAzureLogin)
  {
    if (!user && pathname !== '/') 
    {
      return <Navigate replace to="/" />;
    }

    console.log("hasRole admin: ", hasRole('Admin'));
    console.log("hasRole tutor: ", hasRole('Tutor'));

    if(!user)
    {
      return (
        <LoginScreen azureAPI={azureAPI} redirectTo={fullPath} />
      );
    } 

    console.log(user);  
    if(user && !(hasRole('Admin') || hasRole('Tutor')))
    {
      return (
          <div style={{width:500, margin:'100px auto', textAlign:'center', fontSize:20}}>You don't have permission to access the Tutoring Admin. Please go to MyAccess and request access</div>
      );
    }
  }
  
  return ( 
        <Routes>
          <Route path="/" element={<Admin />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
    );  
}

function App () {
  return (
    <UserProvider>
      <AppBody />
    </UserProvider>
  );
}

export default App;
