import React from "react";

const Avatar = ({ width, thumb }) => {
  return (
    <div
      style={{
        width: width,
        height: width,
        overflow: "hidden",
        borderRadius: "50%",
      }}
    >
      <img
        style={{ objectFit: "cover", width: "100%", height: "100%" }}
        alt="thumb"
        src={
          thumb
            ? thumb
            : "https://d24mswg82xnqvq.cloudfront.net/images/circle.png"
        }
      />
    </div>
  );
};

export default Avatar;
