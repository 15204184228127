import { uploadThumbnail } from "./Network";

const AppId = '53c0035e075c460da4d6e4d5fdae657f';

export const getTimeZoneOffSet = () => {
  var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
  return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
}


export const timeToSec = (str) => {
  var p = str.split(":"),
    s = 0,
    m = 1;

  while (p.length > 0) {
    var curr = parseInt(p.pop(), 10);
    if (!isNaN(curr)) {
      s += m * curr;
      m *= 60;
    }
  }
  return s;
};

export const resizeImage = async (
  binaryData,
  fileType,
  size,
  quality,
  callback
) => {
  const image = new Image();
  image.onload = function (imageEvent) {
    let canvas = document.createElement("canvas");
    let max_size = size;
    let width = image.width;
    let height = image.height;
    if (width > height) {
      if (width > max_size) {
        height *= max_size / width;
        width = max_size;
      }
    } else {
      if (height > max_size) {
        width *= max_size / height;
        height = max_size;
      }
    }
    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d").drawImage(image, 0, 0, width, height);
    callback(canvas.toDataURL(fileType, quality));
  };
  image.src = binaryData;
};

export const onThumbnailUploadChange = async (
  e,
  setThumbUrl,
  courseId,
  size = 1000
) => {
  if (!courseId) {
    console.log("onThumbnailUploadChange activated with no courseId!!!");
    return;
  }
  var file = e.target.files[0];
  if (file) {
    var reader = new FileReader();
    reader.onload = function (readerEvt) {
      const fileData = readerEvt.target.result;
      // resize thumbnail to 1000x1000
      resizeImage(fileData, "image/jpeg", size, 0.85, (resizedImage) => {
        const data = {
          file: resizedImage,
          course: courseId,
        };

        // upload thumbnail image
        uploadThumbnail(data).then((res) => {
          setThumbUrl(res.url);
        });
      });
    };
    reader.readAsDataURL(file);
  }
};

const getAgoraRtmToken = async (userId) => {
  const result = await fetch(`https://api.tutoring-dev.pearsondev.tech/v1/sessions/agora/getRtmToken?userId=${userId}`, { method:'get'})
  return result.json();
}

const convertFiles = async () => {
  const headers = {
    token: 'NETLESSSDK_YWs9TXBSMWFPZDZfZ1pZcng2SyZub25jZT0yMTBiMjA1MC0xNDA2LTExZWQtOTIyYS04ZGY5YzhkN2MzZWImcm9sZT0wJnNpZz1kNjY3ZjBhODBlMjM1YzMzMDY3ZTZlOWJlN2ZiN2JmMzc0OThjMTU1MjM5OTNkOGE1M2JjYTEwYzJmZjlhZjUy',
    region: 'gb-lon',
    'Content-Type': 'application/json',
  }
  const data = {
    resource: 'https://d13hgauxz9vfrb.cloudfront.net/courses/anp2/thumbnails/45bf36dc-cf38-44d7-adec-5a6c81eda8db',
    type: 'static',
  }

  const files = await fetch('https://api.netless.link/v5/services/conversion/tasks', {method:'post', body: JSON.stringify(data), headers})
  return files;
}


export const joinSessionAgora = async (userId, sessionId, closeAgora) => {
    
  window.AgoraEduSDK.config({
      appId: AppId,
      region: 'EU'
  });

  const rtmToken = await getAgoraRtmToken(userId)
  const files = await convertFiles();

  const launchOption = {
      rtmToken: rtmToken,
      userUuid: userId,
      userName: userId,
      roomUuid: `${sessionId} `,
      roleType: 1,
      roomType: 4,
      roomName: 'Agora',
      pretest: true,
      language: "en",
      startTime: new Date().getTime(), 
      duration: 60 * 300,
      courseWareList: [
        {
                resourceName: "video example",
                resourceUuid: "1",
                ext: "mp4",
                url: "https://b-cdn-video.clutchprep.com/videos/20F-U01-CHEM-CH1-ClassificationOfMatter-C-1-V-2-final__720p__1597321836500.mp4",
                size: 0
        },
        {
            resourceName: "family1",
            resourceUuid: "2",
            ext: "jpeg",
            url: "https://d24mswg82xnqvq.cloudfront.net/assets/family1.jpg",
            size: 0
    },
    {
        resourceName: "family2",
        resourceUuid: "3",
        ext: "jpeg",
        url: "https://d24mswg82xnqvq.cloudfront.net/assets/family2.jpg",
        size: 0
    }
    ],
      listener: (evnt) => {
          // If event is 2 - close the session
          if( evnt === 2) {
              closeAgora(false)
          }
      },
  }

  await window.AgoraEduSDK.launch(document.querySelector('#poc'), {
      ...launchOption
  });
}

export function downloadTextAsFile(blob, filename) {
  var a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = filename;

  // Append the anchor element to the body
  document.body.appendChild(a);

  // Programmatically trigger a click event on the anchor element
  a.click();

  // Remove the temporary anchor element from the document
  document.body.removeChild(a);
}

export const levels = [
   'Beginner',
  'Intermediate',
  'Advanced',
];