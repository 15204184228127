import React from "react";
import { useEffect, useState } from "react";
import Avatar from "../Tutors/Avatar";
import { joinSession, getContentEnv, getSessionData, tutoringApiUrl, buildHash } from "../../../components/Shared/Network";
import './Sessions.scss';
import { getTimeZoneOffSet } from '../../Shared/Utils';
import { useUserContext } from  '../../Providers/UserContext';
import { Backdrop, Button, CircularProgress } from "@mui/material";


const SessionCard = ({ session, templates, tutors, setEditSession, setDisplayStatus }) => {
  const [template, setTemplate] = useState(null);
  const [tutor, setTutor] = useState(null);
  const [planDate] = useState(new Date(session.date));
  const [showLoadingOverlay, setShowLoadingOverlay] = React.useState(false);
  const [sessionData, setSessionData] = useState();
  const [showSessionData, setShowSessionData] = useState(true);
  const [showPopUpSessionData, setShowPopUpSessionData] = useState(false);
  const [joined, setJoined] = useState(0);
  const [userData, setUserData] = useState();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const force = params.force === 'true';

  const { hasRole, user, userToken } = useUserContext();

  const timeTillSessionHours = ( new Date(session.date).getTime() - new Date().getTime()) / 3600000;
  const timeTillSessionMinutes = ( new Date(session.date).getTime() - new Date().getTime()) / 60000;

  // Class will be disable if starts in more than 15 minutes
  // If there are subscribers - It will be available 45 after starts
  const disableClass = (user.username !== tutor?.email 
    || timeTillSessionMinutes > 15 
    || (session._count.sessionUsers > 0  ? timeTillSessionMinutes < -45 : timeTillSessionMinutes < 0)) 
    && !force;

  const setData = (data) => {
    setShowSessionData(false);
    setJoined(data?.elosData?.data?.attendees?.length)
    setSessionData(data)
    setUserData(data?.elosData?.data.attendees
    .map((user) => {
        return {
          moderator: user.moderator,
          userId: user.ext_user_id,
          duration: `${Math.floor(user.duration / 60)}m:${user.duration % 60}s`,
          log: {
            joins: user.joins,
            leaves: user.leaves
          }
        }
    }))
  }

  useEffect(() => {
    for (let i = 0; i < templates.length; i++) {
      if (templates[i].id === session.templateId) {
        setTemplate(templates[i]);
      }
    }
  }, [templates, session]);

  useEffect(() => {
    for (let i = 0; i < tutors.length; i++) {
      if (tutors[i].id === session.tutorId) {
        setTutor(tutors[i]);
      }
    }
  }, [tutors, session]);

  const copySessionLink = (sessionId) => {
      // undo color for all the previous links
      const copyButtons = document.getElementsByClassName('copy-link');
      for (let item of copyButtons) {
        item.style.backgroundColor = "#ffffff";
        item.innerHTML = 'Copy Link';
    }
    const hash = buildHash(tutor.email);
      navigator.clipboard.writeText(
        `${tutoringApiUrl()}/sessions/${sessionId}/joinSessionTutor/?redirect=true`);
      const linkButton = document.getElementById(`copy-link-${sessionId}`);
      linkButton.style.backgroundColor = '#baf923'
      linkButton.innerHTML = 'Link copied'
  }

  return (
    <div className={`session-wrap session-card 
      ${(user && user.username === tutor?.email) ? 'active-tutor' : ''}
       ${ timeTillSessionHours < 1 && timeTillSessionHours > 0 ? 'current-session' : ''}`}>
      <div>
        <div className="session-hour"> {`${planDate.getHours()}:${String(planDate.getMinutes()).padStart(2, '0')}`}</div>
        <div className="session-timeZone">{Intl.DateTimeFormat().resolvedOptions().timeZone}<br /> GMT{getTimeZoneOffSet()}</div>
        <div className="session-id">id: {session.id}</div>
      </div>
    
      <div className="session-data">
        <div style={{width: "100px"}}>
          {tutor ? <Avatar width={52} thumb={tutor.thumb} /> : <div className="session-card-pending-tag">PENDING</div>}
          </div>
        <div>
          {session.templateId ? <div className="session-title">
            {template ? `${template.title} - ${template.level}` : 
            <div className="session-card-pending-template-missing">TEMPLATE DELETED</div>}
          </div> : <div className="session-card-pending-template-missing">TEMPLATE DELETED</div>}
          {tutor && tutor.firstName && tutor.lastName && (
            <div style={{ fontSize: 13, marginBottom: 4 }}>
              {tutor.firstName + " " + tutor.lastName}
            </div>
          )}
          <div className="session-details">
            <div>{"Total partcipants: " + session.maxParticipants}</div>
            <div>{"Enrolled: " + (session._count && session._count.sessionUsers ? session._count.sessionUsers : 0)}</div>
            {
            (hasRole('Admin') || (user && user.username === tutor?.email)) &&
             showSessionData && 
             <div className="get-session-data" onClick={()=> getSessionData(session.id, userToken, setData)}>Get session data</div>}
            {sessionData && 
              <>
                <div style={{ cursor: "pointer"}}onClick={() => joined && setShowPopUpSessionData(true)}>{"Joined: " + (joined || 0)}</div>
                {showPopUpSessionData && <div className="userdata-popup">{userData && userData?.map((user) => {
                  return (
                  <>
                  <div>Moderator: {user.moderator.toString()}</div>
                    <div>UserId: {user.userId}</div>
                    <div>Total duration: {user.duration}</div>
                    <div>Joined at:</div> {user?.log?.joins.map((usertime) => {return (<>{usertime} <br/></>)})}
                    <div>Left at:</div> {user?.log?.leaves.map((usertime) => {return (<>{usertime} <br/></>)})}
                    <hr style={{width: "100%"}}></hr>
                    <button className="userdata-popup-close-btn" onClick={() => setShowPopUpSessionData(false)}>X</button>
                  </>
                  )
                })}</div>}
                
                <div onClick={() => sessionData?.video && window.open(sessionData?.video, '_blank')}>{sessionData?.video && "Recording"}</div>
              </> 
            }
          </div>
         
        </div>
      </div>
      <div className="session-material">
        {template && template.filesList && template.filesList.map((file) => {
          
          return (
            <div
            key={file.name}
            className='material-file'
            title={file.name}
            onClick={()=> window.open((getContentEnv() === 'prod'? 'https://d11s3x8mtmgzr2.cloudfront.net' : 'https://d24mswg82xnqvq.cloudfront.net' ) +`/assets/${session.templateId}/${file.name}`, '_blank').focus()}
            >
              {file.name}
            </div>)
        })}
      </div>
      <div className="session-action-buttons">
        <Button
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            // if (timeTillSessionHours > 1 && force) {
            //   if(!window.confirm('The session starts in more than an hour.\n Are you sure you want to join?'))
            //       {
            //           return;
            //       }
            // }
            // if(user.username !== tutor?.email && force){
            //     if(!window.confirm('You are not the tutor for this class.\nAre you sure you want ?'))
            //     {
            //         return;
            //     }
            // }

              setShowLoadingOverlay(true);
              joinSession(session.id, user?.username, (sessionUrl) => {
                setShowLoadingOverlay(false);
                if (sessionUrl === 'Unauthorized - Invalid hash') {
                  if(!window.alert('You are not the register tutor!'))
                  {
                      return;
                  }
                  
                } else if (sessionUrl.message)
                     {
                      if(!window.alert(sessionUrl.message))
                  {
                      return;
                  }
                } else {
                  window.open(sessionUrl, '_blank');
                }
              })
          }}
          variant="contained"
          className={`session-start-button ${disableClass ? 'start-disabled' : ''}`}
          disabled={disableClass}
        >
          Start
        </Button>
        

        {hasRole('Admin') && (
          <><div
            size="small"
            id={`copy-link-${session.id}`}
            onClick={() => copySessionLink(session.id, user?.username)}
            style={{ color: 'blue', textDecoration: 'underline', fontSize: '12px' }}
            className="copy-link"
          >
            Copy Link
          </div><div
            className="session-edit-button"
            onClick={(e) => {
              setEditSession(session);
            } }
          >
              Edit
            </div></>
        )}
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoadingOverlay}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
    
  );
};

export default SessionCard;
