import { useNavigate } from "react-router-dom";
import { useUserContext } from '../Providers/UserContext';
import { AppBar, createStyles, createTheme, Toolbar, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      fontSize: 20,
    },
    AppBarS: {
      // position: "fixed",
      backgroundColor: "#395c8a",
    },
    title: {
      // flexGrow: 1,
      cursor: "pointer",
    },
    options: {
      flexGrow: 12,
      display: "flex",
    },
    option: {
      marginRight: 20,
      cursor: "pointer",
      color: "#ccc",
      "&:hover": {
        color: "#fff",
      },
    },
    optionSel: {
      fontWeight: 700,
      marginRight: 20,
      color: "white",
    },
    barButtons: {
      backgroundColor: "#333",
    },
    barTextButton: {
      cursor: "pointer",
      marginRight: 20,
      color: "#ccc",
      "&:hover": {
        color: "#fff",
      },
    },
  })
);

const theme = createTheme({
  palette: {
    primary: blue,
  },
});

const openSupportForm = () => {
  window.open('https://pearson.tfaforms.net/3036','_blank');
}

const EditNavBar = ({ tab, setTab }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const { logout, hasRole } = useUserContext();

  return (
    <AppBar position="fixed" className={classes.AppBarS}>
      <Toolbar>
        <Typography
          onClick={(e) => {
            navigate("/");
          }}
          variant="h6"
          className={classes.title}
        >
          Tutoring - Admin
        </Typography>
        <div style={{ width: 100 }}></div>
        <div className={classes.options}>
          {/* <div
                className={
                  classes.option +
                  ' ' +
                  (tab === 'Dashboard' ? classes.optionSel : '')
                }
                onClick={(e) => {
                  setTab('Dashboard');
                }}
              >
                Dashboard
              </div> */}
          <div
            className={
              classes.option +
              " " +
              (tab === "Sessions" ? classes.optionSel : "")
            }
            onClick={(e) => {
              setTab("Sessions");
            }}
          >
            Sessions
          </div>
          <div
            className={
              classes.option + " " + (tab === "Tutors" ? classes.optionSel : "")
            }
            onClick={(e) => {
              setTab("Tutors");
            }}
          >
            Tutors
          </div>

          <div
            className={
              classes.option +
              " " +
              (tab === "Templates" ? classes.optionSel : "")
            }
            onClick={(e) => {
              setTab("Templates");
            }}
          >
            Templates
          </div>
          {hasRole('Admin') && <div
            className={
              classes.option +
              " " +
              (tab === "Block" ? classes.optionSel : "")
            }
            onClick={(e) => {
              setTab("Block");
            }}
          >
            Block
          </div>}
          {hasRole('Admin') && <div
            className={
              classes.option +
              " " +
              (tab === "Analytics" ? classes.optionSel : "")
            }
            onClick={(e) => {
              setTab("Analytics");
            }}
          >
            Analytics
          </div>}
        </div>

        {/* <ThemeProvider theme={theme}>
          <Button
            style={{ marginRight: 20 }}
            // className={classes.barButtons}
            color="primary"
            variant="contained"
            onClick={(e) => {
              console.log("Save");
              initSave();
            }}
          >
            Save
          </Button>
        </ThemeProvider> */}

        {/* <div
          className={classes.barTextButton}
          onClick={(e) => {
            window
              .open(window.location.href.replace("edit/", ""), "_blank")
              .focus();
          }}
        >
          Preview
        </div> */}

        {/* <div className={classes.barTextButton} onClick={() => {}}>
          Publish
        </div> */}
         <div
          style={{ marginRight: 20 }}
          className={classes.barTextButton}
          onClick={() => openSupportForm()}
        >
          Support
        </div>

        <div
          // style={{ marginLeft: 10 }}
          className={classes.barTextButton}
          onClick={logout}
        >
          Logout
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default EditNavBar;
