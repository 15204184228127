import React, { useEffect, useState } from "react";

import Tutors from "./Tutors/Tutors";
import Templates from "./Templates/Templates";
import Sessions from "./Sessions/Sessions";
import EditNavBar from "./EditNavBar";
import Block from "./Block";
import API from "./API";

// import tutorRes from "../../jsonExamples/tutors.json";
// import templateRes from "../../jsonExamples/templates.json";
// import sessionRes from "../../jsonExamples/sessions.json";

import { getTutors, getTemplates, getSessions } from "../Shared/Network";
import { useUserContext } from "../Providers/UserContext";

const Admin = () => {
  const [tab, setTab] = useState("Sessions");

  const [sessionsData, setSessionsData] = useState();

  const [tutorsData, setTutorsData] = useState();
  const [templatesData, setTemplatesData] = useState();
  const { userToken } = useUserContext();

  const getSessionsRes = (data) => {
    data.sort(function (a, b) {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    setSessionsData(data);
  };

  const getTutorsRes = (data) => {
    data = data.sort((a, b) => {
      if (a.firstName.trim().toLowerCase() > b.firstName.trim().toLowerCase()) {
          return 0
      }
      return -1;
    })
    setTutorsData(data);
  };

  const getTemplatesRes = (data) => {
    data = data.sort((a, b) => {
      if (a.title?.trim().toLowerCase() > b.title?.trim().toLowerCase()) {
          return 0
      }
      return -1;
    })
    setTemplatesData(data);
  };

  useEffect(() => {
    getSessions(userToken, getSessionsRes);
    getTutors(userToken, getTutorsRes);
    getTemplates(userToken, getTemplatesRes);
  }, []);

  return (
    <>
      <EditNavBar tab={tab} setTab={setTab} />
      {tutorsData && templatesData && sessionsData && (
        <div style={{ marginTop: 100 }}>
          {tab === "Tutors" && (
            <Tutors templates={templatesData} tutors={tutorsData} />
          )}
          {tab === "Templates" && (
            <Templates 
              templatesData={templatesData}
              setTemplatesData={setTemplatesData}
              tutors={tutorsData} 
            />
          )}
          {tab === "Sessions" && sessionsData && (
            <Sessions
              templates={templatesData}
              tutors={tutorsData}
              sessions={sessionsData}
            />
          )}
             {tab === "Block" && (
            <Block
              templates={templatesData}
              tutors={tutorsData}
              sessions={sessionsData}
            />
          )}
          {tab === "Analytics" && (
            <API
              templates={templatesData}
              tutors={tutorsData}
              sessions={sessionsData}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Admin;
