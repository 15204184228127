import CryptoJS from "crypto-js";


export const getContentEnv = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params.contentEnv || process.env.REACT_APP_CONTENT_ENV || "prod";
};

export const buildHash = (id) => {
  return CryptoJS.SHA1(id +  process.env.REACT_APP_HASHSALT).toString();
};

export const tutoringApiUrl = () => {
  switch (getContentEnv()) {
    case "prod":
      return "https://api.tutoring-prd.pearsonprd.tech";
    case "stage":
      return "https://api.tutoring-stg.pearsondev.tech";
    default:
      return "https://api.tutoring-dev.pearsondev.tech";
  }
};

const adminApiUrl = () => {
  switch (getContentEnv()) {
    case "stage":
    case "qa":
      return "https://sgge75fznh.execute-api.us-east-1.amazonaws.com/dev/";
    default:
      return "https://sgge75fznh.execute-api.us-east-1.amazonaws.com/dev/";
  }
};

export const apiGetUserList = async (fromDate, token) => {
  const url = `${tutoringApiUrl()}/_internal/sessions/getuserlist/${fromDate}?csv=true`;
  const requestOptions = {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await fetch(url, requestOptions);
    return res.blob();
  } catch (e) {
    console.log("error:", e.message);
    throw e;
  }
};

export const getPollsData = async (fromDate, token) => {
  const url = `${tutoringApiUrl()}/_internal/sessions/getuserpolls/${fromDate}?csv=true`;
  const requestOptions = {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await fetch(url, requestOptions);
    return res.blob();
  } catch (e) {
    console.log("error:", e.message);
    throw e;
  }
};

export const getUserFeedbacksData = async (fromDate, token) => {
  const url = `${tutoringApiUrl()}/_internal/feedback/responses/${fromDate}?csv=true`;
  const requestOptions = {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await fetch(url, requestOptions);
    return res.blob();
  } catch (e) {
    console.log("error:", e.message);
    throw e;
  }
};

const generalGet = async (url, token, callback) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await fetch(url, requestOptions);
    if (!res.ok) {
      return {};
    }
    const data = await res.json();
    callback(data);
    return;
  } catch (e) {
    console.log("error:", e.message);
    throw e;
  }
};

export const uploadThumbnail = async (data) => {
  const url = `${adminApiUrl()}images`;
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(data),
  };

  try {
    const res = await fetch(url, requestOptions);
    if (!res.ok) {
      return {};
    }
    const resJson = await res.json();
    return resJson;
  } catch (e) {
    console.log("error:", e.message);
    return {};
  }
};

export const getLangDescription = async (
  templateId,
  language,
  setNewTemplateLang,
  userToken
) => {
  return await generalGet(
    tutoringApiUrl() +
      `/_internal/templates/${templateId}/getLangDescription/${language}`,
    userToken,
    setNewTemplateLang
  );
};

export const addLangDescription = async (
  templateId,
  language,
  data,
  updateTemplateLang,
  userToken
) => {
  return await generalSave(
    tutoringApiUrl() +
      `/_internal/templates/${templateId}/addLangDescription/${language}`,
    data,
    userToken,
    updateTemplateLang
  );
};

export const getSessionData = async (sessionId, userToken, saveData) => {
  return await generalGet(
    tutoringApiUrl() + `/_internal/sessions/${sessionId}/getSessionData`,
    userToken,
    saveData
  );
};

export const generalDelete = async (url, token, callback) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await fetch(url, requestOptions);
    // if (!res.ok) {
    //   return {};
    // }
    const data = await res.json();
    callback(data);
    return;
  } catch (e) {
    console.log("error:", e.message);
    throw e;
  }
};

export const generalSave = async (url, data, token, callback) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  try {
    const res = await fetch(url, requestOptions);
    if (!res.ok) {
      return {};
    }
    const data = await res.json();
    callback(data);
    return;
  } catch (e) {
    console.log("error:", e.message);
    throw e;
  }
};

export const getTutors = async (token, callback) => {
  generalGet(tutoringApiUrl() + "/_internal/tutors/all", token, callback);
};

export const deleteTutor = async (tutorId, token, callback) => {
  generalDelete(
    tutoringApiUrl() + "/_internal/tutors/" + tutorId,
    token,
    callback
  );
};

export const saveTutor = async (tutorId, data, token, callback) => {
  generalSave(
    tutoringApiUrl() + "/_internal/tutors/" + (tutorId ? tutorId : ""),
    data,
    token,
    callback
  );
};

export const getTemplates = async (token, callback) => {
  generalGet(tutoringApiUrl() + "/_internal/templates/all", token, callback);
};

export const saveTemplate = async (templateId, data, token, callback) => {
  generalSave(
    tutoringApiUrl() + "/_internal/templates/" + (templateId ? templateId : ""),
    data,
    token,
    callback
  );
};

export const uploadFile = async (templateId, data, token, callback) => {
  uploadFileToSession(
    tutoringApiUrl() +
      "/_internal/templates/uploadFile/" +
      (templateId ? templateId : ""),
    data,
    token,
    callback
  );
};

export const deleteTemplate = async (templateId, token, callback) => {
  generalDelete(
    tutoringApiUrl() + "/_internal/templates/" + templateId,
    token,
    callback
  );
};

export const getSessions = async (token, callback) => {
  generalGet(tutoringApiUrl() + "/_internal/sessions/all", token, callback);
  // generalGet("http://localhost:8080/_internal/sessions/all", token, callback);
};

export const saveSession = async (sessionId, data, token, callback) => {
  generalSave(
    tutoringApiUrl() + "/_internal/sessions/" + (sessionId ? sessionId : ""),
    data,
    token,
    callback
  );
};

export const deleteSession = async (sessionId, token, callback) => {
  generalDelete(
    tutoringApiUrl() + "/_internal/sessions/" + sessionId,
    token,
    callback
  );
};

export const joinSession = async (sessionId, tutorEmail, callback) => {
  const requestOptions = {
    method: "GET",
  };
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const url = `${tutoringApiUrl()}/sessions/${sessionId}/joinSessionTutor${params.force ? '?force=true': ''}`;
  try {
    const res = await fetch(url, requestOptions);
    const data = await res.json();
    callback(data);
    return;
  } catch (e) {
    console.log("error:", e.message);
    throw e;
  }
};

export const uploadFileToSession = async (url, inputData, token, callback) => {
  const data = new FormData();
  data.append("project", inputData.project);
  data.append("mondlyTopic", inputData.mondlyTopic);
  data.append("id", inputData.id);
  data.append("upload", inputData.uploadToSession);
  data.append("files", inputData.materialFile);
  data.append("discipline", inputData.discipline);
  data.append("level", inputData.level);
  data.append("title", inputData.title);
  data.append("description", inputData.description);
  //   for(let i =0; i < inputData.materialFiles.length; i++) {
  //     data.append("files", inputData.materialFiles[i]);
  // }
  // data.append('deletedFiles', inputData.deletedFiles)
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  };

  try {
    const res = await fetch(url, requestOptions);
    if (!res.ok) {
      return {};
    }
    const data = await res.json();
    callback(data);
    return;
  } catch (e) {
    console.log("error:", e.message);
    throw e;
  }
};

export const addUserToBlockList = async (userId, token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await fetch(
      `${tutoringApiUrl()}/_internal/updateBlockUsersList?userId=${userId}`,
      requestOptions
    );
    if (!res.ok) {
      return {};
    }
    return await res.json();
  } catch (e) {
    console.log("error:", e.message);
    throw e;
  }
};

export const removeUserBlockList = async (userId, token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await fetch(
      `${tutoringApiUrl()}/_internal/removeUserFromBlock?userId=${userId}`,
      requestOptions
    );
    if (!res.ok) {
      return {};
    }
    return await res.json();
  } catch (e) {
    console.log("error:", e.message);
    throw e;
  }
};

export const getBlockUsersList = async () => {
  try {
    const resp = await fetch(`${tutoringApiUrl()}/getBlockUsersList`);
    return await resp.json();
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
