import { Autocomplete, Box, Button, TextField, Modal } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CloseIcon from "@material-ui/icons/Close"
import { levels } from '../../Shared/Utils';
import { useUserContext } from "../../Providers/UserContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import 'dayjs/locale/en-gb';
import dayjs from "dayjs";


const SESSIONTIME = 45 * 60 * 1000;

function transformToSameWeekdayInCurrentWeek(oldDate, currentDate) {
  // const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay();
  const targetDayOfWeek = oldDate.getDay();

  // Calculate the difference in days between the current day and the target day
  const daysDifference = targetDayOfWeek - currentDayOfWeek;

  // Move the old date to the current week
  const transformedDate = new Date(currentDate);
  transformedDate.setDate(currentDate.getDate() + daysDifference);

  // Set the hours and minutes to match the old date
  transformedDate.setHours(oldDate.getHours());
  transformedDate.setMinutes(oldDate.getMinutes());

  return transformedDate;
}

const getTimeFromISODate = (date, sessionId) => {
  const hours = ("0" + new Date(date).getHours()).slice(-2);
  const minutes = sessionId ? String(new Date(date).getMinutes()).padStart(2, '0') : '00';
  return  `${hours}:${minutes}`;
};

const getDateFromISODate = (date) => {
  let a = new Date(date);
  const offset = a.getTimezoneOffset();
  a = new Date(a.getTime() - offset * 60 * 1000);
  return a.toISOString().split("T")[0];
};

    const SessionEdit = ({
      session,
      templates,
      tutors,
      daySessions,
      inline,
      setEditSession,
      sendSaveSession,
      sendDeleteSession,
    }) => {
      const getTemplate = (templateId) => {
        for (let i = 0; i < templates.length; i++) {
          if (templates[i].id === templateId) {
            return templates[i];
          }
        }
      };

      const [date, setDate] = useState(
        session && session.date ? getDateFromISODate(session.date) : ""
      );
      const [time, setTime] = useState(
        session && session.date ? getTimeFromISODate(session.date, session.id) : ""
      );

      const [pTime, setPTime] = useState(inline ? dayjs(session.date) : dayjs().startOf('hour'));

      const [filteredTutors, setFilteredTutors] = useState([]);

      const [template, setTemplate] = useState(
        session && session.templateId ? getTemplate(session.templateId) : undefined
      );

      const [modalOpen, setModalOpen] = useState(false);

      const [filteredTemplates, setFilterTemplates] = useState(templates);
      // remove empty slots and duplications
      const filteredDisciplines = useRef([...new Set(
        templates.map((template) => template.discipline).filter(n => n))]);
      
      const [tutor, setTutor] = useState(session && session.tutorId ? 
        tutors.find((tutor) => tutor.id === session.tutorId) : undefined);

      const [vendor, setVendor] = useState();

      const [tutorId, setTutorId] = useState(
        session && session.tutorId ? session.tutorId : undefined
      );

      const vendorList = useRef([...new Set(
        tutors.map((tutor) => tutor.vendor).filter(n => n))]);

      const [level, setLevel] = useState(template?.level || undefined);

      const [discipline, setDiscipline] = useState(template?.discipline);

      const { userToken } = useUserContext();

      const matchAvailability = (availabilityArr, date, time, sessionDate) => {
        
        let plannedDate = new Date(date + " " + time);

        for (let i = 0; i < availabilityArr.length; i++) {
          const availabilityStartDate = 
              transformToSameWeekdayInCurrentWeek(new Date(availabilityArr[i].start), plannedDate).getTime();
          const availabilityEndDate = 
              transformToSameWeekdayInCurrentWeek(new Date(availabilityArr[i].end), plannedDate).getTime();

          if (availabilityStartDate <= plannedDate.getTime() 
              && (availabilityEndDate - SESSIONTIME) >= plannedDate.getTime())
          {
            return true;
          }
        
        }
      };

      const checkTutorFree = (tutor, date, time) => {
        let plannedDate = new Date(date + " " + time);
        for (let i = 0; i < daySessions.length; i++) {
          if (daySessions[i].tutorId === tutor.id && daySessions[i].templateId) {
            if (
              new Date(daySessions[i].date).getHours() === plannedDate.getHours()
            ) {
              return false;
            }
          }
        }
        return true;
      };

      useEffect(() => {
        let res = tutors;

        // 1. Filter tutors by template
        if (template) {
          res = res.filter(
            (tutor) => tutor.templates.findIndex((e) => e.id === template.id) >= 0
          );
        }
        // 2. Filter tutors based on their availability
        res = res.filter((tutor) => {
            return matchAvailability(tutor.availability || [], date, time, session.date);
          }
        );

        // 3. Filter tutors that are not scheduled to other sessions
        res = res.filter((tutor) => checkTutorFree(tutor, date, time));
        if (vendor) {
          // 4. Filer tutor based on vendor
          res = res.filter((tutor) => tutor.vendor === vendor);
        }
        setFilteredTutors(res);
      }, [date, template, time, vendor]);

      useEffect(() => {
        let updateTemplates = templates;
        // let updateTemplates = filteredTemplates;
        if (discipline) {
          updateTemplates = updateTemplates.filter((template) => template.discipline === discipline);
        }
        if (level) {
          updateTemplates = updateTemplates.filter((template) => template.level === level);
        }
        if (tutor) {
          updateTemplates = tutor?.templates.map((tutorTemplate) => getTemplate(tutorTemplate.id));
        }

        setFilterTemplates(updateTemplates);
      }, [discipline, level, templates, tutor])

      useEffect(() => {
        if (filteredTutors 
              && tutor 
                && !inline
                  && !filteredTutors.find((cTutor) => cTutor?.id === tutor?.id)) {
          setTutor(undefined);
          setTutorId(undefined);
          setVendor(undefined);
        }

      }, [filteredTutors])

      const updateTime = (cTime) => {
        setPTime(cTime);
        setTime(`${new Date(cTime.$d).getHours()}:${new Date(cTime.$d).getMinutes()}`);
      }


      const updateLevel = (cLevel) => {
        setTutorId(undefined)
        if (cLevel) {
          setLevel(cLevel);
          if (cLevel !== template?.level) {
             setTemplate(undefined);
             setTutor(undefined);
             setVendor(undefined);
          }
        } else {
          setLevel(undefined);
        }
      }

      const updateDiscipline = (cDiscipline) => {
        // setTutorId(undefined)
        if (cDiscipline) {
          setDiscipline(cDiscipline);
          // if current discipline doesn't match the one that is already selected
          if (cDiscipline !== template?.discipline) {
            setTemplate(undefined);
            setTutor(undefined);
            setVendor(undefined);
          }
        } else {
          setDiscipline(undefined);
          
        }
      }

      const updateTutor = (cTutor) => {
        if (cTutor) {
          setTutorId(cTutor.id)
          setTutor(cTutor)
          setVendor(cTutor.vendor)
        } else {
          setTutorId(undefined);
          setTutor(undefined);
          setVendor(undefined);
        }
      }

      const updateTemplate = (cTemplate) => {
        setTemplate(cTemplate);
        if (cTemplate) {
          setLevel(cTemplate.level);
          setDiscipline(cTemplate.discipline  || undefined);  
          setTemplate(cTemplate);
        // Check if current tutor has the template
        if (tutor) {
          if (!tutor.templates.some(item => item.id === cTemplate.id)) {
            setTutor(undefined);
            setTutorId(undefined);
            setVendor(undefined);
          }
        }
        } else {
          setDiscipline(undefined)
          setLevel(undefined)
        }
      }

      

      return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
        <div className={`session-card ${!inline && 'create-new-session'}`}>
           {modalOpen && 
           <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
          >
            <div className="edit-session-modal">
              <div className="edit-session-modal-title">Unsaved changes</div>
              <div className="edit-session-modal-content">
                Do you want to discard edits or continue editing?
              </div>
              <div className="edit-session-modal-action-wrapper">
                <Button 
                  className="edit-session-modal-discard-btn"
                  onClick={(e) => setEditSession(null)}
                >
                    Discard
                </Button>
                <Button 
                  className="edit-session-modal-continue-btn"
                  onClick={() => setModalOpen(false)}
                >
                    Continue editing
                </Button>
              </div>
            </div>
         </Modal> 
           }
         {!inline && <div className="edit-session-title">Create new session</div>}
          {inline && <div
            style={{
              position: "absolute",
              top: 20,
              right: 20,
              color: "gray",
              cursor: "pointer",
            }}
            onClick={(e) => setEditSession(null)}
          >
            <CloseIcon />
          </div>}
          {session.id && <div style={{marginBottom: 10 }}>sessionId: {session.id}</div> }
          <div>
            <div className="edit-session-date-wrapper">
            {!inline && (
              
              <div>
              <TextField
                className="edit-session-date" 
                id="date"
                label="Date*"
                type="date"
                value={date}
                onChange={(e, value) => {
                  setDate(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              </div>
            )}
            <TimePicker 
              label="Time" 
              ampm={false}
              value={pTime}
              format="HH:mm"
              // defaultValue={inline ? dayjs(session.date) : dayjs().startOf('hour')}
              onChange={(value) => {
                updateTime(value);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            </div>
            <div className="edit-session-lesson-details">
              <div className="edit-session-lesson-title">Lesson</div>
              <Autocomplete
                key={level}
                value={level}
                options={levels}
                getOptionLabel={(option) => option}
                onChange={(e, value) => {
                  updateLevel(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Level"
                  />
                )}
              />
              <Autocomplete
                key={discipline}
                value={discipline}
                options={filteredDisciplines.current.map((disp) => disp).sort()}
                getOptionLabel={(option) => option}
                onChange={(e, value) => {
                  updateDiscipline(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Category"
                  />
                )}
              />
            
              <Autocomplete
                id="templates-combo-box-demo"
                key={template?.id}
                value={template}
                disabled={!level}
                options={filteredTemplates}
                getOptionLabel={(option) => `${option?.title}`}
                defaultValue={
                  session && session.templateId
                    ? getTemplate(session.templateId)
                    : undefined
                }
                onChange={(e, value) => {
                  updateTemplate(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Lesson*"
                  />
                )}
              />
            </div>
            <div className="edit-session-lesson-details">
              <div className="edit-session-lesson-title">Tutor</div>
              <Autocomplete
                key={vendor}
                value={vendor}
                options={vendorList.current}
                getOptionLabel={(option) => option}
                onChange={(e, value) => {
                  setVendor(value)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Vendor"
                  />
                )}
              />
                <Autocomplete
                  key={tutorId}
                  value={tutor}
                  options={filteredTutors}
                  getOptionLabel={(option) => `${option.firstName}  ${option.lastName}`}
                  onChange={(e, value) => {
                    updateTutor(value)
                  }}
                  renderOption={(props, option) => (
                    <Box 
                       key={option.firstName}
                       component="li"
                       sx={{ '& > img': { mr: 2, borderRadius: "50%" } }}
                       {...props}
                    >
                      <img
                        loading="lazy"
                        width="30"
                        srcSet={option.thumb}
                        src={option.thumb}
                        alt=""
                      />
                     {option.firstName} {option.lastName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Tutor"
                    />
                  )}
              />
            </div>

            <div className="edit-session-action-wrapper">
              <Button
              className={`edit-session-create-btn ${template ? 'enabled' : ''}`}
                variant="contained"
                disabled={!template}
                onClick={(e) => {
                  sendSaveSession({
                    templateId: template.id,
                    tutorId,
                    vendor,
                    date: new Date(date + " " + time).toISOString(),
                    maxParticipants: 6,
                  });
                }}
              >
                {inline ? 'Update' : 'Create Session'}
              </Button>
              
                <Button
                  className="edit-session-cancel-btn"
                  variant="outlined"
                  onClick={(e) => session.id ? sendDeleteSession(session.id, userToken) : setModalOpen(true)}
                >
                 {session.id ? 'Delete' : 'Cancel Progress'}
                </Button>
              
            </div>
          </div>
        </div>
        </LocalizationProvider>
      );
    };

    export default SessionEdit;
