import React, { useState } from "react";

import TutorEdit from "./TutorEdit";
import { deleteTutor, saveTutor } from "../../../components/Shared/Network";
import Avatar from "../Tutors/Avatar";
import { useUserContext } from  '../../Providers/UserContext';
import { Button } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";

const Tutors = ({ tutors, templates }) => {
  const [tutorsData, setTutorsData] = useState(tutors);
  const [tutor, setTutor] = useState(null);
  const { hasRole, userToken } = useUserContext();
  const [editTutor, setEditTutor] = useState(false)


  const deteleTutorRes = (res) => {
    console.log("deteleTutorRes");
    console.log(res);

    let index = tutorsData.findIndex((e) => e.id === res.tutorDelete.id);
    tutorsData.splice(index, 1);

    setTutorsData([...tutorsData]);
    setTutor(null);
    setEditTutor(false)
  };

  const sendDeleteTutor = (tutorId) => {
    deleteTutor(tutorId, userToken, deteleTutorRes);
  };

  const saveTutorRes = (res) => {
    console.log(res);
    if (!res.tutor || (typeof(res.tutor) === 'string' && res.tutor?.includes('fail'))) {
        if(!window.alert('This Email already exists!'))
        {
            return;
        }
    }
    let index = tutorsData.findIndex((e) => e.id === res.tutor.id);
    let a = [...tutorsData];

    if (index >= 0) {
      a[index] = res.tutor;
    } else {
      a.push(res.tutor);
    }

    setTutorsData(a);
    setTutor(null);
    setEditTutor(false)
  };

  const sendSaveTutor = (data) => {
    saveTutor(tutor?.id, data, userToken, saveTutorRes);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {!editTutor ? (
        <div style={{ width: 1024 }}>
          <div style={{ textAlign: "right", marginBottom: 20 }}>
            {hasRole('Admin') && (
              <Button
                variant="contained"
                onClick={(e) => {
                  setEditTutor(true);
                }}
              >
                New Tutor
              </Button>
            )}
          </div>
          {tutorsData.map((tutor) => (
            <div
            key={tutor.id}
              style={{
                border: "1px solid #ccc",
                borderRadius: 12,
                cursor: "pointer",
                marginBottom: 10,
                display: "flex",
                padding: 20,
                gap: 10,
              }}
              onClick={(e) => {
                setTutor(tutor);
                setEditTutor(true)
              }}
            >
              <div style={{}}>
                <Avatar width={64} thumb={tutor.thumb} />
              </div>
              <div>
                <div style={{ fontWeight: 600 }}>
                  {tutor.firstName + " " + tutor.lastName}
                </div>
                <div style={{ color: "#666" }}> {tutor.email}</div>

                <div
                  style={{
                    display: "flex",
                    color: "#666",
                    fontSize: 12,
                    marginTop: 10,
                  }}
                >
                  {/* {tutor.templates.map((templateObj) => (
                    <div key={templateObj.id}>
                      {templates
                        .filter((e) => e.id === templateObj.id)
                        .map((obj) => (
                          <div>
                            {obj.title}
                            <span style={{ marginLeft: 20, marginRight: 20 }}>
                              |
                            </span>
                          </div>
                        ))}
                    </div>
                  ))} */}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div style={{ width: 1024 }}>
          <div style={{ textAlign: "left", marginBottom: 20 }}>
            <div
              onClick={(e) => {
                setTutor(null);
                setEditTutor(false)
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIos fontSize="small" />
                Back
              </div>
            </div>
            <TutorEdit
              key={tutor?.id}
              tutor={tutor}
              templates={templates}
              sendDeleteTutor={sendDeleteTutor}
              sendSaveTutor={sendSaveTutor}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Tutors;
